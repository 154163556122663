
import { Component, Vue, Prop } from 'vue-property-decorator'
import AMapLoader from '@amap/amap-jsapi-loader'
import { mapKey } from '@/utils/config'
import Eharts from 'echarts'
import videojs from 'video.js'
import { drawPolygon } from '@/utils/formatData'

require('video.js/dist/video-js.css')
@Component
export default class WorkCardMap extends Vue {
  private map: AMap.Map | any = ''
  private onlineImg = require('@/assets/icon/monitor/online.svg')
  private onlineImgCursor = require('@/assets/icon/monitor/online2.svg')
  private offImg = require('@/assets/icon/monitor/off.svg')
  private offImgCursor = require('@/assets/icon/monitor/off2.svg')
  private drawer = false
  private drawerData = this.drawerDataBak

  private onlineAmount = ''
  private offlineAmount = ''
  private videoPlay: any = null
  private videoHtml =''

  private statusColorList = [
    '#47A2FFFF',
    '#59CB74FF',
    '#FBD44CFF',
    '#53C8D1FF',
    '#F3657DFF',
    '#9860E5FF',
    '#445289FF',
    '#EBA675FF',
    '#73B988FF',
    '#5452D2FF'
  ]

  get projectId () {
    return this.$route.params.projectId as string
  }

  get drawerDataBak () {
    return {
      deviceId: '',
      deviceStatus: '2',
      deviceTypeName: '',
      deviceNumber: ''
    }
  }

  created () {
    this.getProjectLocation()
  }

  // 页面离开
  destroyed () {
    this.clearVideo()
    if (this.map) {
      this.map.off('click', this.drawerClose())
      this.map.destroy()
    }
  }

  // 获取项目电子围栏
  getProjectLocation () {
    this.$axios.get(this.$apis.project.selectProjectAreaById, {
      projectId: this.projectId
    }).then((res) => {
      this.loadMap(res.projectInfo || [], res.projectLocation || [])
    })
  }

  // 获取地图设备列表
  getCameraList () {
    this.$axios.get(this.$apis.monitor.cameraList, {
      projectId: this.projectId,
      deviceTypeId: '1002'
    }).then(res => {
      this.onlineAmount = res.onlineAmount || 0
      this.offlineAmount = res.offLineAmount || 0
      this.drawEchart()
      // 循坏将设备marker添加到地图
      res.devices.map((item: any) => {
        if (item.longitude && item.latitude) {
          const icon = item.deviceStatus === '1' ? this.onlineImg : this.offImg
          const marker: any = new AMap.Marker({
            icon: new AMap.Icon({
            // 图标的取图地址
              image: this.getIcon(item, false)
            }),
            offset: new AMap.Pixel(-18, -48),
            position: new AMap.LngLat(Number(item.longitude), Number(item.latitude)),
            extData: item
          })
          marker.on('click', (e: any) => this.clickMarker(e))
          this.map.add(marker)
        }
      })
    })
  }

  // 渲染地图
  loadMap (projectInfo: {longitude: number; latitude: number}, list: Array<{ longitude: number; latitude: number; fenceCode: string }>) {
    AMapLoader.load({
      key: mapKey,
      version: '2.0',
      plugins: []
    }).then(() => {
      const center: [number, number] = [Number(projectInfo.longitude), Number(projectInfo.latitude)]
      const map = new AMap.Map('map', {
        center: center,
        zoom: 11
      })
      // 点击地图关闭抽屉
      map.on('click', () => {
        this.drawer && this.drawerClose()
      })
      // 画电子围栏
      drawPolygon(map, list, null, this.drawerClose)
      this.getCameraList()
      this.map = map
    })
  }

  getBackground (value: number) {
    const num = value <= 10 ? value : String(value).substr(String(value).length - 1, 1)
    return this.drawerData.deviceStatus === '2' ? '#B7B7B7' : this.statusColorList[Number(num)]
  }

  // 环形图
  drawEchart () {
    this.$nextTick(() => {
      const pieEchart: any = Eharts.init(this.$refs.echarts as any)
      const option = {
        title: {
          text: '状态统计',
          textStyle: {
            align: 'center',
            color: '#000',
            fontSize: 14,
            fontWeight: 'bold'
          },
          top: 20,
          left: 24
        },
        color: ['#1AD1B0', 'rgb(210, 210, 210)'],
        legend: {
          icon: 'circle',
          itemHeight: 6,
          show: true,
          itemGap: 15,
          right: 20,
          top: '45%',
          orient: 'vertical',
          textStyle: {
            fontSize: 14 // 字体大小
          },
          formatter: (item: string) => {
            return item === '在线' ? `${item}  ${this.onlineAmount}` : `${item}  ${this.offlineAmount}`
          }
        },

        series: [{
          name: 'Line 1',
          type: 'pie',
          clockWise: true,
          radius: ['38%', '65%'],
          center: ['35%', '58%'],
          itemStyle: {
            normal: {
              label: {
                show: false
              },
              labelLine: {
                show: false
              }
            }
          },
          hoverAnimation: false,
          data: [{
            name: '在线',
            value: this.onlineAmount
          }, {
            name: '离线',
            value: this.offlineAmount
          }]
        }]
      }
      pieEchart.setOption(option)
    })
  }

  clickMarker (e: any) {
    const data = e.target.getExtData()
    if (this.drawerData.deviceId !== data.deviceId) {
      this.drawerClose()
      this.drawer = true
      e.target.setOffset(new AMap.Pixel(-22, -64))
      e.target.setIcon(this.getIcon(data, true))
      if (data.deviceModel === 'HIK_ISC') {
        this.$axios.get(this.$apis.monitor.selectDeviceIscUrl, {
          deviceNumber: data.deviceNumber
        }).then(res => {
          this.playVideo(res || '')
        })
      } else {
        // 渲染video标签
        this.playVideo(data.addressLive || '')
      }
      this.drawerData = data
    }
  }

  // 播放视频
  playVideo (addressLive: string) {
    this.videoHtml = `<video
                id="videoPlay"
                ref="videoPlayer"
                class="video-js vjs-default-skin vjs-big-play-centered"
                controls
                autoplay
                muted
                preload="auto"
                style="width: 100%;height: 100%;"
              >
                <source src="${addressLive}" type="application/x-mpegURL" />
              </video>`
    const options: any = {
      bigPlayButton: false,
      textTrackDisplay: false,
      posterImage: true,
      errorDisplay: false,
      controlBar: true
    }
    this.$nextTick(() => {
      this.videoPlay = videojs('videoPlay', options)
      this.videoPlay.play()
    })
  }

  getIcon (data: {deviceStatus: string}, bigShow: boolean) {
    const cursorIndex = bigShow ? 1 : 0
    let state = 1 // 默认离线
    const icon = [
      [this.onlineImg, this.offImg],
      [this.onlineImgCursor, this.offImgCursor] // 点击icon
    ]
    state = data.deviceStatus === '1' ? 0 : 1
    return icon[cursorIndex][state]
  }

  // 抽屉关闭
  drawerClose () {
    if (this.drawer) {
      this.drawer = false
      const markers = this.map.getAllOverlays('marker')
      const marker = markers.filter((item: AMap.Marker) => item.getExtData().deviceNumber === this.drawerData.deviceNumber)
      marker[0].setOffset(new AMap.Pixel(-18, -48))
      marker[0].setIcon(this.getIcon(marker[0].getExtData(), false))
      this.clearVideo()
      this.drawerData = this.drawerDataBak
    }
  }

  clearVideo () {
    this.videoPlay && this.videoPlay.dispose()
    this.videoHtml = ''
  }
}
